import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Columns } from "react-bulma-components"

import Layout from "../../components/Layout"
import JobItem from "../../components/JobItem"


const Jobs = () => {
    const data = useStaticQuery(
        graphql`
            query JobsQuery {
                allMarkdownRemark(
                    filter: {
                        fields: {
                            sourceName: {eq: "jobs"}
                        }
                    }
                    sort: {
                        fields: [frontmatter___date]
                        order: DESC
                    }
                ) 
                {
                    edges {
                        node {
                            id
                            frontmatter {
                                title
                                date(formatString: "DD MMMM YYYY"
                                     locale: "ru-RU")
                            }
                            html
                            fields {
                                sourceName
                            }
                        }
                    }
                }
            }
        `
    )
    const jobs = data.allMarkdownRemark.edges
    const description = 
        "Вакансии. Требуются сотрудники в компанию МОНТАЖЭНЕРГОСЕРВИС"
    return (
        <Layout 
            title="Вакансии" 
            subtitle="На этой странице Вы найдете вакансии нашей компании."
            description={description}
            slug="/main/jobs/"
        >
            {jobs.map(({ node }) => (
                <Columns key={node.id}>
                    <Columns.Column>
                        <JobItem 
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                        >
                            <div dangerouslySetInnerHTML={{ __html: node.html }} />
                        </JobItem>
                    </Columns.Column>
                </Columns>
            ))}
        </Layout>
    )
}

export default Jobs
